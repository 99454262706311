<template>
  <div class="">
    <b-card>
      <b-table
        :items="tableRows"
        responsive
        :fields="tableColumns"
        :bordered="true"
        class="mb-0"
      >

        <template #thead-top>
          <b-tr>
            <b-th>
              <span class="sr-only">Name and ID</span>
            </b-th>
            <b-th
              variant="secondary"
              colspan="2"
            >
              Before
            </b-th>
            <b-th
              colspan="2"
              variant="primary"
            >
              After
            </b-th>
          </b-tr>
        </template>

        <template #cell(header)="data">
          <div class="font-weight-bolder">
            {{ data.item.header.ref }}
          </div>
          <div class="font-small-2 text-muted">
            {{ data.item.header.count }}
          </div>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard, BTable, BTr, BTh,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BTr,
    BTh,
  },
  props: {
    cohort: {
      type: Object,
      required: true,
    },
    stats: {
      type: Array,
      required: true,
    },
  },
  setup() {

  },
  computed: {
    tableColumns() {
      const baseProdName = this.cohort.base_products[0].name
      const targetProdName = this.cohort.target_products[0].name
      const columns = [
        { label: 'Cohort', key: 'sub_cohort_id', sortable: false },
        { label: baseProdName, key: 'base_before', sortable: false },
        { label: targetProdName, key: 'target_before', sortable: false },
        { label: baseProdName, key: 'base_after', sortable: false },
        { label: targetProdName, key: 'target_after', sortable: false },
      ]

      if (true) {
        return columns
      }
      const { analysis } = this.stats[0]

      return columns.concat(analysis.map(s => ({
        label: s.name,
        key: s.id,
        sortable: false,
        thClass: 'center-align',
      })))
    },
    tableRows() {
      if (!this.stats) {
        return []
      }

      return this.stats

      // return this.stats.map(s => {
      //   const { analysis, ref, count } = s
      //   return analysis.reduce((obj, cur) => ({ ...obj, [cur.id]: cur }), { header: { ref, count } })
      // })
    },
  },
})
</script>

<style >

</style>
