<template>
  <div v-if="currentCohort">
    <CohortAnalysisStats
      :stats="currentStats"
      :cohort="currentCohort"
    />
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import store from '@/store'

import CohortAnalysisStats from '../components/CohortAnalysisStats.vue'

let isRouteBusy = false

const { mapGetters, mapActions } = createNamespacedHelpers('cohort')

const populateCurrentCohort = (id, next) => {
  if (isRouteBusy) {
    return
  }
  isRouteBusy = true

  store.dispatch('cohort/getCohortForEdit', id).then(item => {
    isRouteBusy = false
    next(vm => vm.setCurrentCohort(item))
  }, () => {
    isRouteBusy = false
  })
}

export default {
  components: {
    CohortAnalysisStats,
  },
  beforeRouteEnter(to, from, next) {
    populateCurrentCohort(to.params.cohort_id, next)
  },
  beforeRouteUpdate(to, from, next) {
    populateCurrentCohort(to.params.cohort_id, next)
  },
  data() {
    return {
      currentCohort: null,
    }
  },
  computed: {
    currentStats() {
      if (!this.currentCohort) { return [] }
      return this.listAnalysisStatsForCohort(this.currentCohort.id)
    },
    ...mapGetters(['listAnalysisStatsForCohort']),
  },
  methods: {
    setCurrentCohort(item) {
      this.currentCohort = item
      this.getCohortAnalysisStats({ force: true, cohortId: item.id })
    },
    ...mapActions(['getCohortAnalysisStats']),
  },
}
</script>
